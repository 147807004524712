import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
import _imports_0 from '../assets/fxh-qrcode.png';
const _hoisted_1 = {
  class: "bd44 flex-col"
};
const _hoisted_2 = {
  class: "outer10 flex-col"
};
const _hoisted_3 = {
  class: "block11 flex-row justify-between mt-20"
};
const _hoisted_4 = {
  class: "outer11 flex-col justify-between"
};
const _hoisted_5 = {
  class: "block12 flex-row justify-between"
};
const _hoisted_6 = {
  class: "wrap2 flex-row justify-between"
};
const _hoisted_7 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[0] || (_cache[0] = _createElementVNode("span", {
    class: "word61"
  }, "友情链接:", -1)), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "mod15 flex-col cursor-pointer ml-10",
      key: index
    }, [_createElementVNode("a", {
      href: item.url,
      target: "_blank",
      class: "word62"
    }, _toDisplayString(item.title), 9, _hoisted_7)]);
  }), 128))])]), _cache[1] || (_cache[1] = _createElementVNode("div", {
    class: "block13 flex-row justify-between"
  }, [_createElementVNode("span", {
    class: "word66"
  }, "主办单位：贵阳市法学会"), _createElementVNode("span", {
    class: "word67"
  }, "联系我们：0851-88935022"), _createElementVNode("span", {
    class: "info28"
  }, "ICP备案号：黔ICP备2021003702号")], -1))]), _cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "flex-col align-center"
  }, [_createElementVNode("div", {
    class: "outer12"
  }, [_createElementVNode("img", {
    class: "qr-code-box",
    src: _imports_0,
    alt: ""
  })]), _createElementVNode("span", {
    class: "txt25 mt-20"
  }, "扫一扫，关注官方公众号")], -1))]), _cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "block14 flex-col"
  }, null, -1))]), _cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "bottom-text"
  }, [_createElementVNode("span", {
    class: "info29"
  }, "©"), _createElementVNode("span", {
    class: "info30"
  }, " 2020-2022 贵阳市法学会（www.gysfxh.org.cn)")], -1))]);
}